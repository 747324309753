<template>
    <v-app id="inspire">
        <div class="px-4">
            <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
            <v-container class="container--fluid grid-list-md text-left" :style="{ fontSize: '15px',  color: '#465a75' }">
                <v-icon :style="{ color: '#465a75',paddingBottom: '11px', marginRight: '5px' }" left-bottom x-large>mdi-file-document</v-icon> Transaction via CSV
            </v-container>
            <v-card flat shaped tile class="pa-1">
                <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card-title>
                        <v-container>
                            <v-layout row wrap align-center>
                                <v-row no-gutters>
                                    <v-col cols="6" md="2" :style="{ marginTop: '20px' }">
                                        <v-text :style="{ color: '#465a75'}"> Merchant Selection: </v-text>
                                    </v-col>
                                    <v-col cols="6" md="2">
                                        <v-select single-line outlined class="ma-2" label="Select a Merchant" :items="merchantList" item-text="name" item-value="merchantId" @change="searchMerchant" data-cy="module-search-merchant"></v-select>
                                    </v-col>
                                    <v-col cols="6" md="2" :style="{ marginTop: '20px' }">
                                            <v-text :style="{ color: '#465a75'}"> Transaction Description: </v-text>
                                    </v-col>
                                    <v-col class="d-flex" cols="6" md="4">
                                        <v-flex xs12>
                                            <textarea type="text" v-model="transactionDescription"></textarea>
                                        </v-flex>
                                    </v-col>
                                </v-row>
                            </v-layout>
                        </v-container>
                        <v-container>
                            <v-layout row wrap align-center>
                                <v-row no-gutters>  
                                    <v-col cols="6" md="2" :style="{ marginTop: '20px' }">
                                        <v-text :style="{ color: '#465a75'}"> Expiry Date: </v-text>
                                    </v-col>
                                    <v-col cols="6" md="2">
                                        <v-flex xs12>
                                            <v-menu v-model="expiryDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-expiry">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field class="ma-2" single-line outlined label="Expiration date" append-icon="mdi-calendar" readonly :value="displayExpirationDate" v-on="on" data-cy="date-menu-expiry"></v-text-field>
                                                </template>
                                                <v-date-picker locale="en-in" v-model="expiryDateVal" no-title @input="expiryDateMenu = false" :min="minExpiryDate" :max="maxExpiryDate" data-cy="date-menu-expiry"></v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-col>
                                    <v-col cols="6" md="2" :style="{ marginTop: '20px' }">
                                        <v-text :style="{ color: '#465a75'}"> Receipt Remarks: </v-text>
                                    </v-col>
                                    <v-col cols="6" md="4">
                                        <v-flex xs12 data-cy="module-receipt-remarks">
                                            <textarea type="text" v-model="receiptRemarks"></textarea>
                                        </v-flex>
                                    </v-col>
                                </v-row>    
                            </v-layout>
                        </v-container>
                        <v-container>
                            <v-layout row wrap align-center>
                                <v-row no-gutters>
                                    <v-col cols="4" md="2" :style="{ marginTop: '20px' }">
                                        <v-text :style="{ color: '#465a75'}"> Expiry Time: </v-text>
                                    </v-col>
                                    <v-col cols="4" md="2">
                                        <v-dialog ref="dialog" v-model="timeModal" :return-value.sync="expiryTimeVal" persistent width="300px" data-cy="time-menu-picker">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field class="ma-2" single-line outlined v-model="expiryTimeVal" label="Expiration Time" append-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" data-cy="time-menu-picker"></v-text-field>
                                                </template> 
                                                <v-time-picker v-if="timeModal" v-model="expiryTimeVal" full-width  format="ampm" use-seconds data-cy="time-menu-picker">
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="primary" @click="timeModal = false" data-cy="button-cancel">Cancel</v-btn>
                                                    <v-btn text color="primary" @click="$refs.dialog.save(expiryTimeVal)" data-cy="button-confirm">Confirm</v-btn>
                                                </v-time-picker>
                                        </v-dialog>
                                    </v-col>
                                </v-row>
                            </v-layout>
                        </v-container>
                        <v-container>
                            <v-layout row wrap align-center>
                                <v-row no-gutters>
                                    <v-col cols="4" md="2" :style="{ marginTop: '20px' }">
                                        <v-text :style="{ color: '#465a75'}"> File Selection: </v-text>
                                    </v-col>
                                    <v-col class="d-flex" cols="4" md="2">
                                        <v-btn class="ma-2" x-large color="#1d6499" :style="{ color: 'white' }" :loading="isSelecting" @click="handleFileImport()">
                                            {{ label }}
                                        </v-btn>
                                    <input class="d-none" type="file" id="file" ref="uploader" v-on:change="handleFileUpload()"/>
                                    </v-col>
                                </v-row>
                            </v-layout>
                        </v-container>
                    </v-card-title>
                </div>
            </v-card>
            <v-card class="ma-20" flat>
                <v-layout>
                    <v-row no-gutters>
                        <v-col cols="12" md="4">
                        </v-col>
                        <v-col cols="12" md="3">
                        </v-col>
                        <v-col cols="12" md="1">
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-btn x-large color="#1d6499" :style="{ color: 'white' }" @click.stop="submitFile">Submit</v-btn>
                        </v-col>
                    </v-row> 
                </v-layout>
            </v-card>
        </div>
    </v-app>
</template>

<script>
import API from '@/api/API'
import SwitchAPI from '@/api/SwitchAPI'
import Loading from '@/views/components/Loading.vue'

export default {
    name: 'fileUpload',
    components: {
        Loading,
    },
    computed: {
        displayExpirationDate() {
            return this.expiryDateVal
        }
    },
    data(){
        return {
            label: "Select CSV File",
            isFileUploaded: false,
            isSelecting: false,
            merchants: [],
            merchantList: this.merchants ?? [],
            selectedMerchant: '',
            options: {},
            timeModal: false,
            expiryDateMenu: false,
            expiryDateVal: undefined,
            expiryTimeVal: undefined,
            minExpiryDate: "1997-01-01",
            maxExpiryDate: undefined,
            loading: true,
            transactionDescription: undefined,
            receiptRemarks: undefined,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            isHtml: false,
            file: ''
        }
    },
    watch: {
        options: {
            handler() {
                this.setData()
            },
        },
        merchants: {
            handler(value) {
                this.merchantList = value
            }
        },
        deep: true,
    },
    async mounted() {
        this.setData()
    },
    methods: {
        handleFileImport() {
            this.isSelecting = true;

            // After obtaining the focus when closing the FilePicker, return the button state to normal
            window.addEventListener('focus', () => {
                this.isSelecting = false
            }, { once: true });
            
            // Trigger click on the FileInput
            this.$refs.uploader.click();
        },
        handleFileUpload() {
            this.file = this.$refs.uploader.files[0];
            this.label = `${this.file.name}`
        },
        async submitFile(){
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            try {
                this.loadingMessage = `Uploading File <strong><i>${this.file.name}</i></strong>`
                let formData = new FormData();
                // file
                // merchantId

                //--new--//
                // expirationDate
                // transactionDescription
                // receiptRemarks
                formData.append('file', this.file);
                formData.append('merchantId', this.selectedMerchant)
                formData.append('expirationDate', this.expiryDateVal + " " + this.expiryTimeVal)
                formData.append('transactionDescription', this.transactionDescription)
                formData.append('receiptRemarks', this.receiptRemarks)
                const response = await SwitchAPI.uploadFile(formData)
                this.loadingFinished = true
                if (!response || response.error) {
                    //error getting data
                    console.log(`${response.error}`)
                    this.isHtml = false
                    this.loadingMessage = response.error
                } else {
                    this.loadingMessage = `Successfully uploaded <strong><i>${this.file.name}</i></strong>. Automatically downloaded receipt file.`
                    console.log("Upload Successfull")
                }
            } catch (e) {
                console.log(e)
                this.isHtml = false
                this.loadingMessage = e.error
            }
            this.loading = false

        },
        searchMerchant: async function (val) {
            this.selectedMerchant = val;
        },
        getMerchantId() {
            const attributes = this.attributes ? JSON.parse(this.attributes) : {}
            const merchants = attributes ? attributes.merchant ?? [] : []
            const merchantString = merchants ? merchants.length > 0 ? merchants[0] : '' : ''
            if (merchantString !== '') {
                const merchant = JSON.parse(merchantString)
                const merchantId = merchant.merchantId
                return merchantId
            }
            return merchantString
        },
        async setData() {
            try {
                const listResponse = await API.getMerchants()
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`)
                } else {
                    this.merchants = listResponse.merchants
                }
            } catch (e) {
                console.log(e)
            }
        },
        closeDialog(type) {
            console.log('type:', type);
            if (type === 'loading') {
                this.loadingDialog = false
                this.$router.go(0);
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },

    },
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%
}

textarea{
    width: 100%;
    border: 2px solid grey;
    border-radius: 7px;
    height: 70px;
    font-family: Verdana, Helvetica, sans-serif;
    padding: 5px;
}
</style>
